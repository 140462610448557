.wrapper {
  padding-top: 15px;
}

body h3 {
  margin-bottom: 25px;
}

.navbar-brand a {
  color: #ffffff;
}

.form-wrapper,
.table-wrapper {
  max-width: 500px;
  margin: 0 auto;
}

.table-wrapper {
  max-width: 700px;
}

.edit-link {
  padding: 7px 10px;
  font-size: 0.875rem;
  line-height: normal;
  border-radius: 0.2rem;
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  margin-right: 10px;
  position: relative;
  top: 1px;
}

.edit-link:hover {
  text-decoration: none;
  color: #ffffff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.photo {
  width: 300px;
  height: 300px;

  object-position: top;
  object-fit: cover;
}

.box {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.one {
  flex: 1 1 auto;
}

.two {
  flex: 1 1 auto;
}

.three {
  flex: 1 1 auto;
}

.display {
  flex: 1 1 auto;
}

.customTooltip {
  display: table-cell;
  font-size: small;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #f5f5f5;
  background-color: rgba(255, 255, 255, 255);
  padding-top: 10px;
}

.form {
  display: flex;
  flex-direction: row;
  align-items: normal;
  justify-content: left;
  height: 100%;
}

.button {
  font-size: small;
  margin-top: 5px;
  margin-bottom: 5px;
}

.react-bootstrap-table table {
  table-layout: fixed;
}

.admin-row {
  align-items: flex-start;
  margin-top: 20px;
}

.admin-col {
  justify-content: left;
  flex-direction: column;
  justify-content: center;
}

body {
  padding-top: 2px;
}
.alert {
  padding-top: 2px;
}

.container1 {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  height: 100%;
}

.stickyHeader {
  position: sticky;
  text-align: center;
  top: 0px;
  background-color: #fff;
}

.border-right {
  border-right: 1px solid #808080;
}

.smalltext {
  font-size: small;
}

.mediumtext {
  font-size: small;
}

.card {
  border: 0px;
  height: 200px;
  z-index: 1000;
}
